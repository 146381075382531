import { createComponent } from '@/helpers/alpine';
import { CustomerStore } from '@/services/customer-store';

type TCustomerStore = {
    id_store: string;
    id_storehouse: string;
    id_relay: string;
    name: string;
    store_name_short: string;
};

export default createComponent(() => ({
    storeId: CustomerStore.get()?.id_store ?? undefined,

    updateStore(
        store: TCustomerStore,
        storehouseId: string | undefined,
        isRelay: boolean,
    ) {
        if (!isRelay) {
            const store_name_short = store.name
                .replace('Côté Clôture', '')
                .trim();

            CustomerStore.set(
                {
                    id_store: store.id_store,
                    id_storehouse: storehouseId ? storehouseId : '',
                    id_relay: store.id_relay,
                    store_name: store.name,
                    store_name_short: store_name_short,
                },
                false,
            );

            this.storeId = CustomerStore.get()?.id_store;

            this.setPlaceholder(false, storehouseId);
        } else {
            this.setPlaceholder(true, storehouseId);
        }
    },

    setPlaceholder(isRelay: boolean, storehouseId: string | undefined) {
        const stores = document.querySelectorAll(
            '.js-store',
        ) as NodeListOf<HTMLDivElement>;
        const content = document.querySelector('.js-stock') as HTMLElement;
        let placeholder;

        stores?.forEach((item) => {
            item.classList.add('hidden');
        });

        if (!isRelay) {
            placeholder = document.querySelector(
                `.js-store[data-store="${this.storeId}"]`,
            ) as HTMLDivElement;

            if (typeof this.storeId != 'undefined') {
                placeholder?.classList.remove('hidden');

                this.getProductsStatut(storehouseId as string);
            } else {
                content.innerHTML = '';
            }
        } else {
            placeholder = document.querySelector(
                `.js-store[data-relay="${storehouseId}"]`,
            ) as HTMLDivElement;

            placeholder?.classList.remove('hidden');
            content.innerHTML = '';
        }
    },

    async getProductsStatut(storeId: string) {
        const content = document.querySelector('.js-stock') as HTMLElement;
        const response = await fetch('module/fractostock/ajax', {
            method: 'POST',
            body: 'idStorehouse=' + storeId,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
        });
        const data = await response.json();
        content.classList.remove(...content.classList);
        content.classList.add('js-stock');
        content.classList.add(...data.cssClass.split(' '));
        content.innerHTML = data.content;
        data.subcontent ? (content.innerHTML += ' - ' + data.subcontent) : '';
    },

    init() {
        if (
            typeof this.storeId != 'undefined' &&
            typeof CustomerStore.get()?.id_storehouse != 'undefined'
        ) {
            this.setPlaceholder(false, CustomerStore.get()?.id_storehouse);
        }
    },
}));
