import LazyLoad from 'vanilla-lazyload';

const lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy',
});

const DOMobserver = new MutationObserver(() => {
    lazyLoadInstance.update();
});

if (document.readyState !== 'loading') {
    DOMobserver.observe(document.body, { childList: true, subtree: true });
} else {
    document.addEventListener('DOMContentLoaded', () => {
        DOMobserver.observe(document.body, { childList: true, subtree: true });
    });
}
