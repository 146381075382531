import Cookie from 'js-cookie';

const LOCATION_COOKIE_EXPIRES = 30;

const LocationCookie = Cookie.withConverter<TCustomerLocation>({
    read: (value) => JSON.parse(value),
    write: (value) => JSON.stringify(value),
}).withAttributes({
    expires: LOCATION_COOKIE_EXPIRES,
});

type TDispatchType = 'init' | 'update';
type TCustomerLocation = {
    address: string;
    lat: number;
    lng: number;
};

function CustomerLocationFromGeocoderResult(
    result: google.maps.GeocoderResult,
): TCustomerLocation {
    return {
        address: result.formatted_address,
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
    };
}

const CustomerLocation = {
    get hasLocation() {
        return this.get() !== undefined;
    },

    get address() {
        return this.get()?.address;
    },

    get lat() {
        return this.get()?.lat;
    },

    get lng() {
        return this.get()?.lng;
    },

    get LatLng() {
        return this.lat && this.lng
            ? new google.maps.LatLng(this.lat, this.lng)
            : undefined;
    },

    onInit(cb: (location?: TCustomerLocation) => void) {
        window.addEventListener('customer-location:init', () => cb(this.get()));
    },

    onUpdate(cb: (location?: TCustomerLocation) => void) {
        window.addEventListener('customer-location:update', () =>
            cb(this.get()),
        );
    },

    dispatch(type: TDispatchType) {
        const event = new CustomEvent(`customer-location:${type}`, {
            detail: this.get(),
        });

        window.dispatchEvent(event);
    },

    set(result: google.maps.GeocoderResult) {
        LocationCookie.set(
            'customer-location',
            CustomerLocationFromGeocoderResult(result),
        );

        this.dispatch('update');
    },

    get() {
        const value = LocationCookie.get('customer-location');
        return typeof value == 'string' ? undefined : value;
    },

    load() {
        this.dispatch('init');
    },
};

export { CustomerLocation, type TCustomerLocation };
