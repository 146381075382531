import Cookies from 'js-cookie';

const STORE_COOKIE_EXPIRES = 30;

const StoreCookie = Cookies.withConverter<TCustomerStore>({
    read: (value) => JSON.parse(value),
    write: (value) => JSON.stringify(value),
}).withAttributes({
    expires: STORE_COOKIE_EXPIRES,
});

type TDispatchType = 'update';

type TCustomerStore = {
    id_store: string;
    id_storehouse: string;
    id_relay: string;
    store_name: string;
    store_name_short: string;
};

const CustomerStore = {
    onUpdate(cb: (location?: TCustomerStore) => void) {
        window.addEventListener('customer-store:update', () => {
            cb(this.get());
        });
    },

    dispatch(type: TDispatchType) {
        const event = new CustomEvent(`customer-store:${type}`, {
            detail: this.get(),
        });

        window.dispatchEvent(event);
    },

    set(store: TCustomerStore, reload: boolean = true) {
        StoreCookie.set('customer-store', store);
        StoreCookie.set('storehouse_id', store.id_storehouse);
        StoreCookie.set('relay_id', store.id_relay);

        reload ? window.location.reload() : '';

        this.dispatch('update');
    },

    get() {
        const value = StoreCookie.get('customer-store');
        return typeof value == 'string' ? undefined : value;
    },
};

export { CustomerStore };
export type { TCustomerStore };
