import { createComponent } from '@/helpers/alpine';
import { GoogleMapsAPI } from '@/services/gmaps';
import { Helpers } from '@/helpers';
import {
    CustomerLocation,
    TCustomerLocation,
} from '@/services/customer-location';

const MAP_ZOOM = 5;
const MAP_MIN_ZOOM = 12;

type TStore = {
    id_store: string;
    lat: number;
    lng: number;
    distance: number;
};

function makeMarker(map: google.maps.Map, store: TStore) {
    const { lat, lng } = store;
    const marker = new google.maps.Marker({
        position: { lat, lng },
        map,
    });

    marker.set('id_store', store.id_store);
    return marker;
}

type TStoreMapsOpts = {
    defaultLat: number;
    defaultLng: number;
};

const StoresMap = createComponent((opts: TStoreMapsOpts) => ({
    defaultLat: opts.defaultLat ?? 0,
    defaultLng: opts.defaultLng ?? 0,

    map: undefined as unknown as google.maps.Map,
    bounds: undefined as unknown as google.maps.LatLngBounds,

    markers: [] as google.maps.Marker[],
    stores: [] as TStore[],

    fit(markers: google.maps.Marker[]) {
        this.bounds = new google.maps.LatLngBounds();

        markers
            .map((marker) => marker.getPosition())
            .filter(
                (position): position is google.maps.LatLng =>
                    position !== undefined,
            )
            .forEach((position) => this.bounds.extend(position));

        if (markers.length) {
            this.map.fitBounds(this.bounds);

            const zoom = this.map.getZoom() ?? MAP_ZOOM;
            this.map.setZoom(zoom > MAP_MIN_ZOOM ? MAP_MIN_ZOOM : zoom);
        }
    },

    storesNearBy(location: TCustomerLocation, distance: number) {
        return this.stores
            .map((store) => {
                const { lat, lng } = store;
                const cosine = Helpers.GEO.distanceCosine(
                    { lat, lng },
                    {
                        lat: location.lat,
                        lng: location.lng,
                    },
                );

                store.distance = cosine;
                return store;
            })
            .filter((store) => store.distance / 1000 <= distance)
            .sort((a, b) => a.distance - b.distance);
    },

    onUpdatedCustomerLocation(location?: TCustomerLocation) {
        if (location === undefined) {
            return;
        }

        const stores = this.storesNearBy(location, 100);
        const markers = this.markers.filter((m) =>
            stores.find((s) => s.id_store == m.get('id_store')),
        );

        this.fit(markers);
    },

    async init() {
        GoogleMapsAPI.load();
        await GoogleMapsAPI.ready;

        this.stores = Array.from(
            this.$el.querySelectorAll<HTMLMetaElement>('meta[name^="store"]'),
        ).map<TStore>((meta) => JSON.parse(meta.content));

        this.map = new google.maps.Map(this.$refs.placeholder, {
            zoom: MAP_ZOOM,
            center: {
                lat: this.defaultLat,
                lng: this.defaultLng,
            },
        });

        this.markers = this.stores.map((store) =>
            makeMarker(this.map as google.maps.Map, store),
        );

        this.markers.forEach((marker) => {
            marker.addListener('click', () => {
                this.$dispatch(`store-open-${marker.get('id_store')}`);
            });
        });

        this.fit(this.markers);

        CustomerLocation.onUpdate((location) => {
            this.onUpdatedCustomerLocation(location);
        });
    },
}));

export { StoresMap };
