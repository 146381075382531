import { createComponent } from '@/helpers/alpine';

function swap(array: HTMLElement[], index1: number, index2: number) {
    array[index2] = array.splice(index1, 1, array[index2])[0];
}

const isFullWidth = (item: HTMLElement) =>
    item.classList.contains('col-span-12');

const isHalfWidth = (item: HTMLElement) =>
    item.classList.contains('col-span-6');

const fullWidthAncestorsCount = (items: HTMLElement[], index: number) =>
    items
        .slice(0, index + 1)
        .reverse()
        .splice(1, index)
        .reduce((prev, curr, _, array) => {
            if (isFullWidth(curr)) {
                array.splice(1);
                return prev;
            }

            return prev + 1;
        }, 0);

const iterable = (items: HTMLElement[]) => ({
    items,

    entries: function* entries(): Generator<[number, HTMLElement | undefined]> {
        let i = 0;
        for (const n of this) {
            yield [i++, n];
        }
    },

    [Symbol.iterator]() {
        let index = 0;
        return {
            next: () => {
                if (index == this.items.length) {
                    return { value: undefined, done: true };
                }

                const curr = this.items[index];
                const next = this.items[index + 1];
                const count = fullWidthAncestorsCount(this.items, index);

                if (
                    index === 0 &&
                    isHalfWidth(curr) &&
                    next &&
                    isFullWidth(next)
                ) {
                    swap(this.items, index, index + 1);
                }

                if (index > 0 && count % 2 == 0 && next && isFullWidth(next)) {
                    swap(this.items, index, index + 1);
                }

                return {
                    value: this.items[index++],
                    done: false,
                };
            },
        };
    },
});

const GridBlocks = createComponent(() => ({
    _items: [] as HTMLElement[],

    init() {
        this._items = Array.from(
            this.$el.querySelectorAll<HTMLElement>(':scope > *'),
        );

        const sorted = iterable(this._items);
        for (const [idx, item] of sorted.entries()) {
            item?.classList.add(`order-${idx}`);
        }
    },
}));

export { GridBlocks };
