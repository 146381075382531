import { createComponent } from '@/helpers/alpine';
import { CustomerStore, TCustomerStore } from '@/services/customer-store';

const StoreSelector = createComponent(() => ({
    current: undefined as TCustomerStore | undefined,

    get id_store() {
        return this.current?.id_store;
    },

    get hasStore() {
        return this.current !== undefined;
    },

    open() {
        this.$dispatch('stores-open');
    },

    async init() {
        this.current = CustomerStore.get();
    },
}));

export { StoreSelector };
