import { DataLayer } from '@/services/datalayer';
import { ElementWithXAttributes } from 'alpinejs';
// import prestashop from 'prestashop';

type TElement = ElementWithXAttributes<HTMLElement> | HTMLElement;

// DOM Usage

// <button class="btn" type="button"
//     x-data
//
//     x-dl:push.click="{ test: 'test' }"
//
//     x-dl:emit.click="'testevent'"
//
//     x-intersect="$dl.emit('testevent', $event)"
//
//     @click="$dl.push({ 'test': 'test' })"
//
//     @click="$dl.emit('testevent', $event)"
//     ...
// >
//     test
// </button>

// JS Usage

// Context
const dataLayerContextEl = document.querySelector(
    'meta[name="datalayer-context"]',
) as HTMLMetaElement;

if (dataLayerContextEl == null) {
    throw 'Meta tag name="datalayer-context" not found';
} else {
    const dataLayerContainer = document.querySelector(
        '.js-datalayer-container',
    ) as HTMLElement;
    const dataLayerContext = JSON.parse(dataLayerContextEl.content);

    if (dataLayerContainer) {
        DataLayer.push({
            event: 'page_view',
            breadcrumb_level_1: dataLayerContext.breadcrumb_level_1,
            breadcrumb_level_2: dataLayerContext.breadcrumb_level_2,
            breadcrumb_level_3: dataLayerContext.breadcrumb_level_3,
            breadcrumb_level_4: dataLayerContext.breadcrumb_level_4,
            breadcrumb_level_5: dataLayerContext.breadcrumb_level_5,
            client_id: dataLayerContext.client_id,
            client_type: dataLayerContext.client_type,
            code_ape: dataLayerContext.code_ape,
            env: dataLayerContext.env,
            groupe_tarifaire: dataLayerContext.groupe_tarifaire,
            page_type: dataLayerContext.page_type,
            societe: dataLayerContext.societe,
            user_status: dataLayerContext.user_status,
            user_id: dataLayerContext.user_id,
        });
    }
}

// Customer
DataLayer.on('customer', (el) => {
    const customer = JSON.parse(el.dataset.dlCustomer as string);

    if (customer.event === 'sign_up') {
        customer.event = 'login';

        DataLayer.push(customer);

        DataLayer.push({
            event: 'creation_compte',
            item_click: 'valider_creation',
            item_value: 'particulier',
        });
    }
});

// Panier
DataLayer.on('cart-confirm', (el) => {
    const products = JSON.parse(el.dataset.dlItems as string);

    DataLayer.push({
        event: 'panier',
        item_click: 'clic_cta',
        item_value: 'commander',
    });

    DataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            value: Number(el.dataset.dlValue),
            coupon: el.dataset.dlCoupon ? el.dataset.dlCoupon : null,
            items: products,
        },
    });
});

// Panier quantité produit
DataLayer.on('cart-qty', (el) => {
    const product = JSON.parse(el.dataset.dlProduct as string);
    const value = el.dataset.dlValue;
    const valueNumb = value?.replace(/[^\d.-]/g, '') as string;
    product.quantity = 1;

    DataLayer.push({
        event: el.dataset.dlType,
        ecommerce: {
            value: parseFloat(valueNumb),
            items: [product],
        },
    });
});

// Listing produits
const itemObserver = new IntersectionObserver(
    (items) => {
        const displayedItems = [] as Array<TElement>;
        items.forEach((item) => {
            if (item.isIntersecting) {
                displayedItems.push(
                    JSON.parse(
                        item.target.getAttribute('data-product') as string,
                    ),
                );
            }
        });

        if (displayedItems.length > 0) {
            DataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    items: displayedItems,
                },
            });
        }
    },
    { threshold: 0.5 },
);

document
    .querySelectorAll('.js-datalayer-product')
    .forEach((items) => itemObserver.observe(items));

// Listing produits - promo
const promotionObserver = new IntersectionObserver(
    (promotions) => {
        promotions.forEach((promotion) => {
            if (promotion.isIntersecting) {
                const promotionalItem = JSON.parse(
                    promotion.target.getAttribute('data-dldiscount') as string,
                );
                let promotionalItemSlot =
                    promotion.target.getAttribute('data-dlslot');
                promotionalItem.link_url = promotionalItem.link_url
                    .split('/')
                    .pop();
                const promotionalProduct = JSON.parse(
                    promotion.target.getAttribute(
                        'data-dlproduct-discount',
                    ) as string,
                );

                if (typeof promotionalProduct != 'undefined') {
                    if (!promotionalItemSlot) promotionalItemSlot = 'homefeed';

                    DataLayer.push({
                        event: 'view_promotion',
                        ecommerce: {
                            creative_name: promotionalItem.link_url,
                            creative_slot: promotionalItemSlot,
                            promotion_id: null,
                            promotion_name: promotionalItem.title_1,
                            items: [promotionalProduct],
                        },
                    });
                } else {
                    DataLayer.push({
                        event: 'view_promotion',
                        ecommerce: {
                            creative_name: promotionalItem.link_url,
                            creative_slot: 'homefeed',
                            promotion_id: null,
                            promotion_name: promotionalItem.title_1,
                        },
                    });
                }
            }
        });
    },
    { threshold: 0.5 },
);

document
    .querySelectorAll('.js-datalayer-product-discount')
    .forEach((promotions) => promotionObserver.observe(promotions));

// Listing produits - Facets
DataLayer.on('facet-change', (el) => {
    const input = el as HTMLInputElement;
    let state = '';

    if (input.checked) {
        state = 'apply_filter';
    } else {
        state = 'remove_filter';
    }

    DataLayer.push({
        event: 'product_list',
        item_click: state,
        item_value: input.name,
    });
});

// Footer
DataLayer.on('footer-menu', (_el, originalEvent) => {
    const element = originalEvent?.target as HTMLLinkElement | undefined;

    if (element?.href) {
        const elementName = element.textContent?.replace(/^\s+|\s+$/g, '');
        DataLayer.push({
            event: 'footer',
            item_click: 'clic_lien_footer',
            item_value: elementName,
        });
    }
});
