import Cookie from 'js-cookie';

type TLoggerLevel = Pick<Console, 'debug' | 'log' | 'info' | 'error' | 'warn'>;

export class Logger {
    name: string;
    level: keyof TLoggerLevel;
    LOGGER_KEY: string;
    DEBUG_KEY = 'FTO_DEBUG';

    constructor(name: string, level: keyof TLoggerLevel = 'info') {
        this.LOGGER_KEY = `${this.DEBUG_KEY}_${name.toUpperCase()}`;
        this.name = name;
        this.level = level;
    }

    get isLoggable() {
        return (
            [Cookie.get(this.LOGGER_KEY), Cookie.get(this.DEBUG_KEY)].indexOf(
                '1',
            ) != -1
        );
    }

    log(message: string, ...args: unknown[]) {
        if (!this.isLoggable) return;
        window.console[this.level](`[${this.name}] ${message}`, ...args);
    }
}
