import { Logger } from '@/helpers/logger';
import { ElementWithXAttributes } from 'alpinejs';
import EventEmitter from 'events';

declare global {
    interface Window {
        dataLayer: unknown[];
        gadl: typeof DataLayer;
    }
}

type TElement = ElementWithXAttributes<HTMLElement> | HTMLElement;

const DataLayerLogger = new Logger('DATALAYER', 'info');

const DataLayerEmitter = new EventEmitter();
DataLayerEmitter.setMaxListeners(30);

const DataLayer = {
    emit(event: string, el: TElement, originalEvent?: Event) {
        DataLayerEmitter.emit(`datalayer:${event}`, el, originalEvent);
    },

    on(event: string, cb: (el: TElement, originalEvent?: Event) => void) {
        DataLayerEmitter.on(`datalayer:${event}`, cb);
    },

    push(...args: unknown[]) {
        window.dataLayer.push(...args);
        DataLayerLogger.log('push', args);
    },

    init() {
        window.dataLayer = window.dataLayer || [];
        window.gadl = this;
    },
} as const;

export { DataLayer };
