import Cookies from 'js-cookie';
import { GoogleMapsAPI } from './gmaps';
import { Helpers } from '@/helpers';

const { todayTime, formatTime, localeDayOfWeek, formatLocaleDay } =
    Helpers.Date;

const GOOGLE_PLACE_CACHE_COOKIE_EXPIRES = 30;

const GooglePlaceCacheCookie = Cookies.withConverter<ICacheGooglePlace[]>({
    read: (value) => JSON.parse(value),
    write: (value) => JSON.stringify(value),
}).withAttributes({
    expires: GOOGLE_PLACE_CACHE_COOKIE_EXPIRES,
});

interface ICacheGooglePlace {
    place_id?: string;
    schedule?: TPlaceOpeningHoursPeriod[];
}

type TPlaceResult = google.maps.places.PlaceResult;
type TPlaceOpeningHoursPeriod = google.maps.places.PlaceOpeningHoursPeriod;

class GooglePlaceStore implements ICacheGooglePlace {
    place_id;
    schedule;

    constructor(place: ICacheGooglePlace) {
        this.place_id = place.place_id;
        this.schedule = place.schedule;
    }

    toObject(): ICacheGooglePlace {
        return {
            place_id: this.place_id,
            schedule: this.schedule,
        };
    }

    get isEmpty() {
        return [this.place_id, this.schedule].includes(undefined);
    }

    get isOpen() {
        if (this.isEmpty) {
            return undefined;
        }

        const today = new Date();
        const todayDay = today.getDay();

        const schedule = this.schedule?.find((entry) => {
            const { hours: openH, minutes: openM } = entry.open;
            const { hours: closeH, minutes: closeM } = entry.close ?? {};

            return (
                Object.values(entry).find(({ day }) => day === todayDay) &&
                todayTime(openH, openM).getTime() < today.getTime() &&
                today.getTime() < todayTime(closeH, closeM).getTime()
            );
        });

        return schedule === undefined ? false : true;
    }

    get nextClose() {
        const today = new Date();
        const todayDay = today.getDay();
        const schedule = this.schedule?.find((entry) => {
            const { hours: openH, minutes: openM } = entry.open;
            const { hours: closeH, minutes: closeM } = entry.close ?? {};

            return (
                Object.values(entry).find(({ day }) => day === todayDay) &&
                todayTime(openH, openM).getTime() < today.getTime() &&
                today.getTime() < todayTime(closeH, closeM).getTime()
            );
        }) as TPlaceOpeningHoursPeriod;

        const { hours, minutes } = schedule.close ?? {};

        return {
            day: formatLocaleDay(today),
            time: formatTime(hours, minutes),
        };
    }

    get nextOpen() {
        const today = new Date();
        const todayDay = today.getDay();
        const schedule = (this.schedule?.find((entry) => {
            const { hours: openH, minutes: openM } = entry.open;
            return (
                Object.values(entry).find(({ day }) => day === todayDay) &&
                todayTime(openH, openM).getTime() > today.getTime()
            );
        }) ??
            this.schedule?.find((entry) => {
                const idx = Helpers.Array.range(todayDay + 1, 7).find(
                    (idx) => idx == entry.open.day,
                );

                return entry.open.day == idx;
            }) ??
            this.schedule?.shift()) as TPlaceOpeningHoursPeriod;

        const { hours, minutes, day } = schedule.open;

        return {
            day: day == todayDay ? '' : localeDayOfWeek(day),
            time: formatTime(hours, minutes),
        };
    }

    static async find(place_id: string) {
        await GoogleMapsAPI.ready;

        let cache = GooglePlaceCacheCookie.get('google-place-cache');
        cache = Array.isArray(cache) ? cache : [];

        const cached_place = cache.find((c) => c.place_id);
        if (cached_place) {
            return new GooglePlaceStore(cached_place);
        }

        let resolver: (
            value: TPlaceResult | PromiseLike<TPlaceResult> | null,
        ) => void;
        const query = new Promise<TPlaceResult | null>((resolve) => {
            resolver = resolve;
        });

        const placesholder = document.createElement('div');
        document.body.append(placesholder);

        const service = new google.maps.places.PlacesService(placesholder);
        service.getDetails(
            {
                placeId: place_id,
            },
            (place) => {
                resolver(place);
            },
        );

        placesholder.remove();

        const place = await query;
        const google_place_store = new GooglePlaceStore({
            place_id: place?.place_id,
            schedule: place?.opening_hours?.periods,
        });

        if (google_place_store.isEmpty) {
            return;
        }

        cache.push(google_place_store.toObject());
        GooglePlaceCacheCookie.set('google-place-cache', cache);

        return google_place_store;
    }
}

export { GooglePlaceStore };
