import { createComponent } from '@/helpers/alpine';

type TSpinnerOpts = {
    inputRef: string;
    value: number;
    step: number;
    min?: number;
    max?: number;
};

const Spinner = createComponent((opts: TSpinnerOpts) => ({
    inputRef: opts.inputRef,
    value: opts.value ?? 1,
    step: opts.step ?? 1,
    min: opts.min,
    max: opts.max,

    get input() {
        return this.$refs[this.inputRef];
    },

    more() {
        this.value = this.value + this.step;
        if (this.max !== undefined) {
            this.value = this.value > this.max ? this.max : this.value;
        }

        this.$nextTick(() => {
            this.input.dispatchEvent(new Event('input'));
        });
    },

    less() {
        this.value = this.value - this.step;
        if (this.min !== undefined) {
            this.value = this.value < this.min ? this.min : this.value;
        }

        this.$nextTick(() => {
            this.input.dispatchEvent(new Event('input'));
        });
    },
}));

export { Spinner };
