import { createComponent } from '@/helpers/alpine';
import { debounce } from 'lodash-es';
import prestashop from 'prestashop';
import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';
import AbortAddon from 'wretch/addons/abort';
import { TEntries, THTMLElementEvent } from '~types/common';
import { EL, Helpers } from '@/helpers';
import { AplineDispatchedEvent } from 'alpinejs';

type TElasticSearchOpts = {
    active?: boolean;
};

type TQueryResult = {
    products: TProductHitRaw[];
    pagination: {
        total_items: number;
    };
};

type TProductHitRaw = {
    name: string;
    image_url: string;
    legend: string;
    url: string;
    qty_combination: number;
    regular_price_amount: number;
    price_amount: number;
    regular_price: string;
    price: string;
    has_discount: boolean;
    discount_percentage: string;
    discount_amount_to_display: string;
    discount_type: string;
    cover: {
        legend: string;
        medium: {
            url: string;
        };
    };
    lowest_price: {
        price: string;
        price_amount: number;
        percent: number;
        regular_price: string;
        regular_price_amount: number;
        has_discount: boolean;
        reduction: number;
        reduction_type: string;
    };
};

type TProductHit = {
    name: string;
    image_url: string;
    image_legend: string;
    url: string;
    qty_combination: number;
    price: string;
    regular_price: string;
    price_amount: number;
    regular_price_amount: number;
    has_discount: boolean;
    discount_percentage: string;
    discount_amount_to_display: string;
    discount_type: string;
    lowest_price: {
        price: string;
        price_amount: number;
        percent: number;
        regular_price: string;
        regular_price_amount: number;
        has_discount: boolean;
        reduction: number;
        reduction_type: string;
    };
};

function displayPrice(price: number, taxLabel: boolean = true) {
    const tax = prestashop.configuration.display_prices_tax_incl ? 'TTC' : 'HT';
    return [
        Intl.NumberFormat(prestashop.language.locale, {
            style: 'currency',
            currency: prestashop.currency.iso_code,
        }).format(price),
        taxLabel ? tax : null,
    ]
        .filter(Boolean)
        .join(' ');
}

const hitTransformer = ({
    name,
    cover,
    url,
    price_amount,
    regular_price_amount,
    has_discount,
    qty_combination,
    discount_percentage,
    discount_amount_to_display,
    discount_type,
    lowest_price,
}: TProductHitRaw): TProductHit => ({
    name,
    url,
    image_url: cover.medium.url,
    image_legend: cover.legend,
    qty_combination,
    price: displayPrice(price_amount),
    regular_price: displayPrice(regular_price_amount, false),
    price_amount,
    regular_price_amount,
    has_discount,
    discount_percentage,
    discount_amount_to_display,
    discount_type,
    lowest_price,
});

const Search = createComponent((opts: TElasticSearchOpts) => ({
    active: opts.active ?? true,
    controller: null as AbortController | null,
    state: false,
    query: '',
    hits: [] as TProductHit[],
    total: 0,

    get isOpen() {
        return this.state;
    },

    get isClosed() {
        return !this.state;
    },

    reset() {
        this.controller?.abort();
        this.hits = [];
        this.total = 0;
    },

    open() {
        this.toggle(true);
    },

    close() {
        this.toggle(false);
        this.reset();
        this.query = '';
    },

    toggle(force?: boolean | undefined) {
        this.state = force !== undefined ? force : !this.state;

        this.$nextTick(() => {
            this.state ? this.$refs.input.focus() : this.$refs.input.blur();
        });
    },

    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key == 'Escape') {
            this.close();
        }
    },

    async onSearch() {
        this.reset();

        if (this.query.length == 0) {
            return;
        }

        const [controller, request] = wretch(prestashop.urls.pages.search, {
            cache: 'no-cache',
        })
            .addon(QueryStringAddon)
            .addon(AbortAddon())
            .options({})
            .query({
                s: this.query,
                time: new Date().getTime(),
                resultsPerPage: 20,
                ajax: 1,
            })
            .get()
            .controller();

        this.controller = controller;
        const results = await request.json<TQueryResult>();

        this.hits = results.products.map<TProductHit>(hitTransformer);
        this.total = results.pagination.total_items;
    },

    init() {
        window.addEventListener('keyup', this.handleKeyboardEvent.bind(this));

        if (this.active) {
            this.$watch('query', debounce(this.onSearch.bind(this), 150));
            this.onSearch();
        }
    },
}));

type TUpdatedProductListKeys =
    | 'rendered_facets'
    | 'rendered_lateral_facets'
    | 'rendered_products_top'
    | 'rendered_products'
    | 'rendered_products_bottom'
    | 'rendered_products_header';

type TUpdatedProductListData = {
    [key in TUpdatedProductListKeys]: string;
};

const FACETS_REPLACE_MAP: { [key in TUpdatedProductListKeys]: string } = {
    rendered_products_header: '#js-product-list-header',
    rendered_facets: '#js-facets',
    rendered_lateral_facets: '#js-lateral-facets',
    rendered_products_top: '#js-product-list-top',
    rendered_products: '#js-product-list',
    rendered_products_bottom: '#js-product-list-bottom',
};

const Facets = createComponent(() => ({
    isFacetsOpen: false,
    openGroups: [] as string[],

    facetsOpen() {
        this.isFacetsOpen = true;
    },

    facetsClose() {
        this.isFacetsOpen = false;
        this.openGroups = [];

        this.$dispatch('facets-close');
    },

    groupOpen(group: string) {
        if (this.isGroupOpen(group)) {
            return;
        }

        this.openGroups.push(group);
        this.$dispatch(`facets-group-open-${group}`);
    },

    groupClose(group: string) {
        this.openGroups.slice(this.openGroups.indexOf(group), 1);
    },

    isGroupOpen(group: string) {
        return this.openGroups.includes(group);
    },

    onInput(event: THTMLElementEvent<HTMLInputElement, InputEvent>) {
        const facetUrl = Helpers.String.hex2bin(event.target.value);
        prestashop.emit('updateFacets', facetUrl);
    },

    onUpdateProductListDOM(data: TUpdatedProductListData) {
        (<TEntries<typeof FACETS_REPLACE_MAP>>(
            Object.entries(FACETS_REPLACE_MAP)
        )).forEach(([key, selector]) => {
            EL.replaceHTML(selector, data[key]);
        });
    },

    init() {
        window.addEventListener('facets-update', (event) => {
            const facetUrl = Helpers.String.hex2bin(
                (<AplineDispatchedEvent>event).detail as string,
            );

            prestashop.emit('updateFacets', facetUrl);

            window.scrollTo({
                top: this.$el.offsetTop - 16,
                behavior: 'smooth',
            });
        });

        window.addEventListener('popstate', () => {
            prestashop.emit('updateFacets', window.location.href);
        });

        prestashop.on('updateProductList', (data: TUpdatedProductListData) => {
            this.onUpdateProductListDOM(data);
        });
    },
}));

const Elastic = {
    Facets,
    Search,
};

export { Elastic };
