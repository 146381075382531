import { createComponent } from '@/helpers/alpine';

enum ECheckoutStepIdentifier {
    INFORMATION = 'checkout-personal-information-step',
    ADRESSES = 'checkout-addresses-step',
    DELIVERY = 'checkout-delivery-step',
    CHECKOUT = 'checkout-payment-step',
}

enum ECheckoutStepIdentifierIcon {
    INFORMATION = 'i-user',
    ADRESSES = 'i-location',
    DELIVERY = 'i-truck',
    CHECKOUT = 'i-payment-card',
}

type TCheckoutStepOpts = {
    identifier: ECheckoutStepIdentifier;
    isCurrent: boolean;
    isReachable: boolean;
};

const CheckoutStep = createComponent((opts: TCheckoutStepOpts) => ({
    identifier: opts.identifier,
    isCurrent: opts.isCurrent,
    isReachable: opts.isReachable,

    get id() {
        const idx = Object.values(ECheckoutStepIdentifier).indexOf(
            this.identifier,
        );

        return Object.keys(ECheckoutStepIdentifier)[
            idx
        ] as keyof typeof ECheckoutStepIdentifierIcon;
    },

    get isUnreachable() {
        return !this.isCurrent && !this.isReachable;
    },

    get isValidated() {
        return !this.isCurrent && this.isReachable;
    },

    get icon() {
        return ECheckoutStepIdentifierIcon[this.id];
    },

    goTo() {
        if (this.isCurrent || this.isUnreachable) {
            return;
        }

        this.$dispatch('checkout:update-step', this.identifier);
    },

    scrollToStep(identifier: string) {
        setTimeout(() => {
            const stepElement = document.querySelector('#' + identifier);

            if (stepElement) {
                const error = document.querySelectorAll(
                    '.js-field-error',
                ) as NodeListOf<HTMLElement>;

                if (error.length) {
                    window.scrollTo({
                        top: error[0].offsetTop - 16,
                        behavior: 'smooth',
                    });
                } else {
                    const elementPosition =
                        stepElement.getBoundingClientRect().top +
                        window.scrollY;
                    const offsetPosition = elementPosition - 100;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }
            }
        }, 100);
    },

    init() {
        if (this.isCurrent) {
            this.$dispatch('checkout:update-step', this.identifier);
            this.scrollToStep(this.identifier);
        }
        window.addEventListener('checkout:updated-step', (event) => {
            this.isCurrent = (event as CustomEvent).detail == this.identifier;
        });
    },
}));

export { CheckoutStep };
