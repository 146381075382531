import { createApp } from '@/helpers/alpine';

import '@/lib/lazyload';

import '@/common/tagging';

import { Elastic } from '@/modules/elastic';
import fractostock from '@/modules/fractostock';
import ps_emailsubscription from '@/modules/ps_emailsubscription';
import ps_shoppingcart from '@/modules/ps_shoppingcart';
import netreviews from '@/modules/netreviews';
import topbanner from '@/modules/topbanner';
import totshippingpreview from '@/modules/totshippingpreview';

import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import datalayer from '@/plugins/datalayer';

import { Toggler } from '@/components/toggler';
import { Sliders } from '@/components/sliders';
import { Spinner } from '@/components/spinner';
import { GridBlocks } from '@/components/grid-blocks';
import { LocationSelector } from '@/components/location-selector';
import { RelaySelector } from '@/components/relay-selector';
import { StoreSelector } from '@/components/store-selector';
import { StoreItem } from '@/components/store-item';
import { StoresMap } from '@/components/stores-map';
import { YoutubePlayer } from '@/components/youtube-player';

import { Header } from '@/partials/header';
import { Footer } from '@/partials/footer';
import { Navigation } from '@/partials/navigation';

import { CartActions } from '@/core/cart-actions';
import { Checkout } from '@/core/checkout';
import { CheckoutStep } from '@/core/checkout-step';

import { CustomerLocation } from '@/services/customer-location';
import { DataLayer } from '@/services/datalayer';

import link from '@/plugins/link';

createApp(
    {
        Core: {
            CartActions,
            Checkout,
            CheckoutStep,
        },
        Components: {
            Sliders,
            GridBlocks,
            Toggler,
            LocationSelector,
            RelaySelector,
            YoutubePlayer,
            Spinner,
            Store: {
                StoreSelector,
                StoreItem,
                StoresMap,
            },
        },
        Partials: {
            Header,
            Footer,
            Navigation,
        },
        Modules: {
            Elastic,
            fractostock,
            ps_emailsubscription,
            ps_shoppingcart,
            netreviews,
            topbanner,
            totshippingpreview,
        },
    },
    [collapse, intersect, datalayer, link],
);

CustomerLocation.load();
DataLayer.init();
