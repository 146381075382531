import { IGenericObject } from '~types/common';
import { DOM } from '@/helpers';
import AlpineInstance, {
    Alpine,
    AlpinePlugin,
    AlpineComponents,
    AlpineComponent,
    Magics,
} from 'alpinejs';

function createApp(components: AlpineComponents, plugins: AlpinePlugin[] = []) {
    plugins.forEach(AlpineInstance.plugin);

    window.App = {
        vm: AlpineInstance,
        ...components,
    };

    DOM.onReady(AlpineInstance.start);
}

function createPlugin(cb: (Alpine: Alpine) => void) {
    return cb;
}

function createComponent<
    K extends IGenericObject,
    T extends AlpineComponent<K>,
>(cb: (opts: K, Alpine: Alpine) => AlpineComponent<T>) {
    return (opts: K) => cb(opts ?? {}, window.App.vm);
}

function getRef<T>(ctx: Magics<T>, ref: string): HTMLElement | undefined {
    return ctx.$refs[ref];
}

export { getRef, createApp, createComponent, createPlugin };
