import { DOM } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import wretch from 'wretch';
import FormUrlAddon from 'wretch/addons/formUrl';

type TNetreviewsOps = {
    groupName: string;
    ajaxUrl: string;
    idShop: string;
    idProduct: string;
    maxPage: number;
};

const netreviews = createComponent((opts: TNetreviewsOps) => ({
    groupName: opts.groupName ?? '',
    ajaxUrl: opts.ajaxUrl ?? '',
    idShop: opts.idShop ?? '',
    idProduct: opts.idProduct ?? '',
    maxPage: opts.maxPage ?? '',
    sortByNote: 0,
    currentPage: 1,
    currentOpt: 'horodate_DESC',

    async nextPage() {
        this.currentPage = this.currentPage + 1;

        const query = wretch(`${this.ajaxUrl}netreviews/ajax-load.php`)
            .addon(FormUrlAddon)
            .formUrl({
                id_product: this.idProduct,
                nom_group: this.groupName,
                id_shop: this.idShop,
                reviews_max_pages: this.maxPage,
                current_page: this.currentPage,
                sortbynote: this.sortByNote,
                current_option_filter: this.currentOpt,
                filter_option: 'more',
            })
            .post();

        const data = await query.text();
        const scroll = this.$refs.reviews.scrollLeft;
        let lastChild = this.$refs.reviews.lastElementChild as HTMLElement;
        let lastIndex = -1;

        if (lastChild) {
            lastIndex = DOM.getChildIndex(this.$refs.reviews, lastChild);
        }

        this.$root.innerHTML = data;

        await this.$nextTick();
        this.$refs.reviews.scrollTo({ left: scroll });

        lastChild = DOM.getChildAt(this.$refs.reviews, lastIndex);
        this.$refs.reviews.scrollTo({
            left: DOM.getChildAt(this.$refs.reviews, lastIndex + 1).offsetLeft,
            behavior: 'smooth',
        });
    },
}));

export default netreviews;
