import { createComponent } from '@/helpers/alpine';
import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';

declare const ajaxUrl: string;
declare const totCart: string;

export default createComponent(() => ({
    zone: '' as string,

    get hasZone() {
        return this.zone !== '';
    },

    async display() {
        const [zone, id_zone] = (this.zone ?? '').split('|');

        const res = await wretch(ajaxUrl)
            .addon(FormDataAddon)
            .formData({
                id: zone,
                id_zone: id_zone,
                id_product: 0,
                id_product_attribute: 0,
                cart: totCart,
                choice: 1,
                total_price: 0,
            })
            .post()
            .json<{ data: string }>();

        const contentEl = this.$root.querySelector(
            '[x-ref="content"]',
        ) as HTMLElement;

        contentEl.innerHTML = res.data;
        this.$nextTick(() => {
            this.$dispatch('shipping-preview-open');
        });
    },
}));
