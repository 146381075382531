import { createComponent } from '@/helpers/alpine';

enum ECheckoutStepIdentifier {
    INFORMATION = 'checkout-personal-information-step',
    ADRESSES = 'checkout-addresses-step',
    DELIVERY = 'checkout-delivery-step',
    CHECKOUT = 'checkout-payment-step',
}

const isValidStep = (
    identifier: string,
): identifier is ECheckoutStepIdentifier =>
    Object.values<string>(ECheckoutStepIdentifier).includes(identifier);

const Checkout = createComponent(() => ({
    currentStep: undefined as ECheckoutStepIdentifier | undefined,

    init() {
        window.addEventListener('checkout:update-step', (event) => {
            const identifier = (event as CustomEvent).detail;

            if (!isValidStep(identifier)) {
                throw `[checkout] ${identifier} is not a valid step identifier.`;
            }

            this.currentStep = identifier;
            this.$dispatch('checkout:updated-step', this.currentStep);
        });
    },
}));

export { Checkout };
