import { createComponent } from '@/helpers/alpine';

type TNavigationDropdowns = 'product' | 'inspiration' | 'service';

const Navigation = createComponent(() => ({
    isNavOpen: false,
    currentIndex: null as TNavigationDropdowns | null,

    navClose() {
        this.currentIndex = null;
        this.isNavOpen = false;
        document.body.classList.toggle('overflow-hidden', this.isNavOpen);
        this.$dispatch('nav-clear');
    },

    navToggle(index: TNavigationDropdowns, force?: boolean | undefined) {
        if (this.currentIndex == null) {
            this.currentIndex = index;
        }

        if (index == this.currentIndex) {
            this.isNavOpen = force !== undefined ? force : !this.isNavOpen;
        }

        document.body.classList.toggle('overflow-hidden', this.isNavOpen);
        this.currentIndex = this.isNavOpen ? index : null;
        this.$dispatch('nav-clear');
    },
}));

export { Navigation };
