import { createComponent } from '@/helpers/alpine';

type TToggleOpts = {
    keyboard: boolean;
    state: boolean;
    props?: object;
};

const Toggler = createComponent((opts: TToggleOpts) => ({
    keyboard: opts.keyboard ?? false,
    props: opts.props ?? {},
    state: opts.state ?? false,

    get isOpen() {
        return this.state;
    },

    get isClosed() {
        return !this.state;
    },

    open(emit?: boolean) {
        this.toggle(true, emit);
    },

    close(emit?: boolean) {
        this.toggle(false, emit);
    },

    toggle(force?: boolean | undefined, emit?: boolean) {
        this.state = force !== undefined ? force : !this.state;

        if (emit) {
            this.$dispatch(this.state ? 'open' : 'close');
        }
    },

    toggleFn(
        cb: (...args: unknown[]) => void,
        force?: boolean | undefined,
        emit?: boolean,
    ) {
        this.toggle(force, emit);
        cb.bind(this)(this);
    },

    destroy() {
        this.$el.removeEventListener('keyup', this.handleKeyboardEvent);
        this.$nextTick(() => {
            document.body.focus();
        });
    },

    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key == 'Escape') {
            this.close();
        }
    },

    init() {
        Object.assign(this, this.props);

        if (this.keyboard) {
            this.$el.addEventListener(
                'keyup',
                this.handleKeyboardEvent.bind(this),
            );
        }
    },
}));

export { Toggler };
