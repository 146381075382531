declare global {
    interface Window {
        onGoogleMapsAPIReady: () => void;
    }
}

let resolveReady: (value?: unknown) => void;
const ready = new Promise((resolve) => {
    resolveReady = resolve;
});

window.onGoogleMapsAPIReady = async () => {
    resolveReady();
};

export const GoogleMapsAPI = {
    ready,

    load() {
        if (document.querySelector('#google-maps-api')) {
            return;
        }

        let callback = '&callback=onGoogleMapsAPIReady';
        if (document.querySelector('.js-relay-map')) {
            callback = '&callback=onRelayDeliveryGoogleMapsAPIReady';
        }

        const GoogleMapsAPIKeyMeta = document.querySelector<HTMLMetaElement>(
            "meta[name='gmap_key']",
        );

        if (!GoogleMapsAPIKeyMeta) {
            return;
        }

        const GoogleMapsAPIKey = GoogleMapsAPIKeyMeta.content;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${GoogleMapsAPIKey}${callback}`;
        script.async = true;

        script.id = 'google-maps-api';
        document.body.appendChild(script);
    },
};
