import { createPlugin } from '@/helpers/alpine';
import { DataLayer } from '@/services/datalayer';
import { ElementWithXAttributes } from 'alpinejs';
import { isArray, isPlainObject, isString } from 'lodash-es';

enum EAllowedTriggers {
    CLICK = 'click',
    SUBMIT = 'submit',
}

enum EAllowedActions {
    EMIT = 'emit',
    PUSH = 'push',
}

type TActionDefs = {
    [key in keyof Record<EAllowedActions, string>]: (
        ...args: TActionArgs
    ) => void;
};

type TActionArgs = [
    payload: unknown,
    el: ElementWithXAttributes<HTMLElement>,
    originalEvent?: Event,
];

const isValidAction = (action: string): action is EAllowedActions =>
    Object.values<string>(EAllowedActions).includes(action);

const isValidTrigger = (trigger: string): trigger is EAllowedTriggers =>
    Object.values<string>(EAllowedTriggers).includes(trigger);

export default createPlugin((vm) => {
    const ACTIONS: TActionDefs = {
        emit: (event, el, originalEvent) => {
            if (!isString(event)) {
                throw `[dl] invalid type for payload '${event}'`;
            }

            DataLayer.emit(event, el, originalEvent);
        },
        push: (payload) => {
            if (!isArray(payload) && !isPlainObject(payload)) {
                throw `[dl] invalid type for payload '${payload}'`;
            }

            DataLayer.push(payload);
        },
    };

    vm.magic('dl', (el) => ({
        emit: (event: string, originalEvent: Event) =>
            ACTIONS.emit(event, el, originalEvent),
        push: (payload: unknown, originalEvent: Event) =>
            ACTIONS.push(payload, el, originalEvent),
    }));

    vm.directive(
        'dl',
        (el, { value, modifiers, expression }, { evaluate, cleanup }) => {
            const action = value;
            const [trigger, ...rest] = modifiers;

            if (rest.length > 0) {
                throw `[dl] only one trigger is allowed.`;
            }

            if (!isValidTrigger(trigger)) {
                throw `[dl] trigger '${trigger}' is not allowed.`;
            }

            if (!isValidAction(action)) {
                throw `[dl] action '${action}' is not allowed.`;
            }

            const handler = (originalEvent: Event) => {
                ACTIONS[action](evaluate(expression), el, originalEvent);
            };

            el.addEventListener(trigger, handler);

            cleanup(() => {
                el.removeEventListener(trigger, handler);
            });
        },
    );
});
