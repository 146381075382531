import { Helpers } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import { debounce } from 'lodash-es';

const Menu = createComponent(() => ({
    _expanded: false,

    init() {
        window.addEventListener(
            'resize',
            debounce(() => {
                this._expanded = Helpers.Screen.isWiderThanBreakpoint('lg');
            }, 200),
        );
    },

    toggle() {
        this._expanded = !this._expanded;
    },

    get expanded() {
        if (Helpers.Screen.isWiderThanBreakpoint('lg')) {
            this._expanded = true;
        }

        return this._expanded;
    },
}));

const Footer = { Menu };

export { Footer };
