import { createPlugin } from '@/helpers/alpine';

export default createPlugin((vm) => {
    vm.directive('link', (el, { expression }, { cleanup }) => {
        el.classList.add('cursor-pointer');

        const handler = () => {
            window.location.href = expression;
        };

        el.addEventListener('click', handler);

        cleanup(() => {
            el.removeEventListener('click', handler);
        });
    });
});
