declare global {
    interface Window {
        onYouTubeIframeAPIReady: () => void;
    }
}

let resolveReady: (value?: unknown) => void;
const ready = new Promise((resolve) => {
    resolveReady = resolve;
});

window.onYouTubeIframeAPIReady = async () => {
    resolveReady();
};

export const YoutubeIframeAPI = {
    ready,

    load() {
        if (document.querySelector('#youtube-iframe-api')) {
            return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.youtube.com/iframe_api';
        script.id = 'youtube-iframe-api';
        document.body.appendChild(script);
    },
};
