import { DOM, Helpers } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import { throttle } from 'lodash-es';

type TBaseSliderNavigation = 'item' | 'page';

type TBaseSliderOptsPerPageBreakpoint = {
    [key: string]: number;
};

type TBaseSliderOpts = {
    initIndex?: number;
    initPage?: number;
    keyboard?: boolean;
    focus?: boolean;
    loop?: boolean;
    perPage?: TBaseSliderOptsPerPageBreakpoint | number;
};

const BaseSlider = createComponent((opts: TBaseSliderOpts) => ({
    initIndex: opts.initIndex ?? 0,
    initPage: opts.initIndex ?? 0,
    keyboard: opts.keyboard ?? false,
    focus: opts.focus ?? false,
    loop: opts.loop ?? false,
    _perPage: opts.perPage ?? 1,

    pages: 0,
    scrollLeft: 0,
    offsetWidth: 0,
    scrollWidth: 0,

    get currentPage() {
        return this.noNext
            ? this.pages - 1
            : Math.floor(this.currentIndex / this.itemsPerPage);
    },

    get currentIndex() {
        const scrollEl = Array.from<HTMLElement>(
            this.$refs.wrapper.querySelectorAll(':scope > *'),
        ).find(
            (el) =>
                el.offsetLeft >= this.scrollLeft &&
                this.scrollLeft < el.offsetLeft + el.offsetWidth,
        ) as HTMLElement;

        return DOM.getChildIndex(this.$refs.wrapper, scrollEl);
    },

    get itemsPerPage() {
        if (typeof this._perPage === 'number') {
            return this._perPage;
        }

        const [, perPage = 1] =
            Object.entries(this._perPage).findLast(([key]) =>
                Helpers.Screen.isWiderThanBreakpoint(key),
            ) ?? [];

        return perPage;
    },

    get totalItems() {
        return DOM.getChildCount(this.$refs.wrapper);
    },

    get isStart() {
        return this.scrollLeft == 0;
    },

    get isEnd() {
        return this.scrollWidth - this.scrollLeft - this.offsetWidth <= 0;
    },

    get noPrev() {
        return this.loop ? false : this.isStart;
    },

    get noNext() {
        return this.loop ? false : this.isEnd;
    },

    prev(navType: TBaseSliderNavigation = 'page') {
        let index = this.noNext ? this.currentIndex : this.currentPage;
        if (this.loop && this.isStart) {
            index = navType == 'page' ? this.pages : this.totalItems;
        }

        this.goTo(index - 1, navType, 'smooth');
    },

    next(navType: TBaseSliderNavigation = 'page') {
        let index = this.currentPage + 1;
        if (this.loop && this.isEnd) {
            index = 0;
        }

        this.goTo(index, navType, 'smooth');
    },

    scrollTo(index: number, navType: TBaseSliderNavigation = 'page') {
        this.goTo(index, navType, 'smooth');
    },

    goTo(
        index: number,
        navType: TBaseSliderNavigation = 'page',
        behavior: ScrollBehavior = 'instant',
    ) {
        const toEl = DOM.getChildAt(
            this.$refs.wrapper,
            index * (navType == 'page' ? this.itemsPerPage : 1),
        );
        this.$refs.wrapper?.scrollTo({
            left: toEl?.offsetLeft ?? 0,
            behavior,
        });
    },

    handleKeyboardEvent(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowLeft':
                this.prev();
                break;
            case 'ArrowRight':
                this.next();
                break;
        }
    },

    handleScrollEvent() {
        const { scrollLeft, offsetWidth, scrollWidth } = this.$refs.wrapper;
        this.scrollLeft = Math.floor(scrollLeft);
        this.offsetWidth = offsetWidth;
        this.scrollWidth = scrollWidth;
    },

    handleResizeEvent() {
        try {
            const childenCount = this.$refs.wrapper.childElementCount ?? 0;
            this.pages = Math.ceil(childenCount / this.itemsPerPage);
        } catch (e) {
            //
        }
    },

    async start() {
        await this.$nextTick();

        this.handleScrollEvent();
        this.$refs.wrapper.addEventListener(
            'scroll',
            throttle(this.handleScrollEvent.bind(this), 150),
            { passive: true },
        );

        this.handleResizeEvent();
        window.addEventListener(
            'resize',
            throttle(this.handleResizeEvent.bind(this), 150),
            { passive: true },
        );

        if (this.focus) {
            this.$el.setAttribute('tabindex', '0');
            this.$el.focus();
        }

        if (this.keyboard) {
            this.$el.addEventListener(
                'keyup',
                this.handleKeyboardEvent.bind(this),
            );
        }

        if (this.initIndex != this.currentIndex) {
            await this.$nextTick();
            this.goTo(this.initIndex, 'item');
        }

        if (this.initPage != this.currentPage) {
            await this.$nextTick();
            this.goTo(this.initIndex, 'page');
        }
    },

    destroy() {
        this.$el.removeEventListener('keyup', this.handleKeyboardEvent);
        this.$el.removeEventListener('scroll', this.handleScrollEvent);
        this.$el.removeEventListener('resize', this.handleResizeEvent);
        this.$nextTick(() => {
            document.body.focus();
        });
    },
}));

const Sliders = { BaseSlider };

export { Sliders };
