import { Helpers } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import { debounce } from 'lodash-es';

const topbanner = createComponent(() => ({
    getHeight() {
        return this.$el.offsetHeight;
    },

    init() {
        const update = () => {
            Helpers.Style.setCssVar(
                '--topbanner-height',
                `${this.getHeight()}px`,
            );
        };

        window.addEventListener('resize', debounce(update, 200));
        update();
    },
}));

export default topbanner;
