import { Helpers } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import { debounce, throttle } from 'lodash-es';

const Header = createComponent(() => ({
    onMenu: false,

    getTopBannerHeight() {
        const height = parseInt(
            Helpers.Style.getCssVar('--topbanner-height').replace('px', ''),
            10,
        );

        return isNaN(height) ? 0 : height;
    },

    getHeaderHeight() {
        return this.getTopBannerHeight() + this.$el.offsetHeight;
    },

    getStickyHeaderHeight() {
        return this.$el.offsetHeight;
    },

    get headerHeight() {
        return parseInt(
            Helpers.Style.getCssVar('--header-height').replace('px', ''),
            10,
        );
    },

    get stickyHeaderHeight() {
        return parseInt(
            Helpers.Style.getCssVar('--sticky-header-height').replace('px', ''),
            10,
        );
    },

    init() {
        const update = () => {
            Helpers.Style.setCssVar(
                '--header-height',
                `${this.getHeaderHeight()}px`,
            );

            Helpers.Style.setCssVar(
                '--sticky-header-height',
                `${this.getStickyHeaderHeight()}px`,
            );
        };

        const scroll = () => {
            const noScroll = this.headerHeight - this.stickyHeaderHeight;

            const scroll =
                window.scrollY <= noScroll
                    ? this.stickyHeaderHeight + noScroll - window.scrollY
                    : this.stickyHeaderHeight;

            Helpers.Style.setCssVar('--sticky-header-scroll', `${scroll}px`);
        };

        window.addEventListener('resize', debounce(update, 200));
        window.addEventListener('resize', debounce(scroll, 200));
        window.addEventListener('scroll', throttle(scroll, 200));

        update();
        scroll();
    },
}));

export { Header };
