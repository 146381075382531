import { IGenericObject, TLocation } from '~types/common';

const Helpers = {
    GEO: {
        distanceCosine(from: TLocation, to: TLocation) {
            const R = 6371e3;
            const p1 = (from.lat * Math.PI) / 180;
            const p2 = (to.lat * Math.PI) / 180;
            const deltaP = p2 - p1;
            const deltaLon = to.lng - from.lng;
            const deltaLambda = (deltaLon * Math.PI) / 180;

            const a =
                Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
                Math.cos(p1) *
                    Math.cos(p2) *
                    Math.sin(deltaLambda / 2) *
                    Math.sin(deltaLambda / 2);

            const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;

            return d;
        },
    },

    Date: {
        formatLocaleDay: (date: Date) => {
            return new Intl.DateTimeFormat(navigator.language, {
                weekday: 'long',
            }).format(date);
        },

        localeDayOfWeek: (day: number) => {
            const dayDate = [...Array(7).keys()]
                .map((d) => {
                    const next = new Date();
                    next.setDate(next.getDate() + d);

                    return next;
                })
                .find((next) => next.getDay() === day);

            return new Intl.DateTimeFormat(navigator.language, {
                weekday: 'long',
            }).format(dayDate);
        },

        todayTime: (hours: number = 0, minutes: number = 0) => {
            const today = new Date();
            return new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                hours,
                minutes,
            );
        },

        formatTime: (
            hours: number = 0,
            minutes: number = 0,
            separator = 'h',
        ) => {
            return [
                hours.toString().padStart(2, '0'),
                minutes.toString().padStart(2, '0'),
            ].join(separator);
        },
    },

    URL: {
        build(
            endpoint: string,
            params: { [key: string]: string | number | boolean },
        ) {
            const url = new URL(endpoint, window.location.origin);

            for (const [k, v] of Object.entries(params)) {
                if (v == null || v.toString().length == 0 || v === false) {
                    continue;
                }

                url.searchParams.append(k, v.toString());
            }

            return url;
        },
    },

    Style: {
        getCssVar: (varName: string) => {
            return getComputedStyle(document.documentElement).getPropertyValue(
                varName,
            );
        },

        setCssVar: (varName: string, value: string) => {
            document.documentElement.style.setProperty(varName, value);
        },

        getBreakpoint(name: string) {
            const val = this.getCssVar(`--breakpoint-${name}`).replace(
                'px',
                '',
            );

            return parseInt(val, 10);
        },
    },

    Screen: {
        isWiderThanBreakpoint(name: string) {
            return window.innerWidth >= Helpers.Style.getBreakpoint(name);
        },
    },

    Mouse: {
        getEventOffset(
            event: MouseEvent,
            target: HTMLElement | Window | Document,
        ) {
            target = target || (event.currentTarget as HTMLElement);

            const cx = event.clientX || 0;
            const cy = event.clientY || 0;
            const rect = EL.getBoundingClientOffset(target);

            return {
                offsetX: cx - rect.left,
                offsetY: cy - rect.top,
            };
        },
    },

    Object: {
        toFormData(obj: IGenericObject<string | Blob>) {
            const formData = new FormData();
            Object.entries(obj).forEach((entry) => formData.append(...entry));

            return formData;
        },
    },

    Array: {
        combine<T>(...allEntries: T[][]): T[][] {
            return allEntries.reduce<T[][]>(
                (results, entries) =>
                    results
                        .map((result) =>
                            entries.map((entry) => result.concat([entry])),
                        )
                        .reduce(
                            (subResults, result) => subResults.concat(result),
                            [],
                        ),
                [[]],
            );
        },

        range: (start: number, stop: number, step = 1) =>
            Array.from(
                { length: (stop - start) / step + 1 },
                (_, index) => start + index * step,
            ),
    },

    String: {
        hex2bin: (str: string) => {
            function hexdec(hex_string: string) {
                hex_string = (hex_string + '').replace(/[^a-f0-9]/gi, '');
                return parseInt(hex_string, 16);
            }

            function chr(AsciiNum: number) {
                return String.fromCharCode(AsciiNum);
            }

            let r = '',
                a = 0;

            for (a = 0; a < str.length; a = a + 2) {
                r = r + chr(hexdec(str.slice(a, a + 2)));
            }

            return r;
        },

        bin2hex: (str: string) => {
            const l = str.length;
            let i = 0,
                chr,
                hex = '';

            for (i; i < l; ++i) {
                chr = str.charCodeAt(i).toString(16);
                hex += chr.length < 2 ? '0' + chr : chr;
            }

            return hex;
        },
    },

    sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    },
};

const DOM = {
    onReady(func: EventListener): void {
        if (document.readyState !== 'loading') {
            return func(new Event('ready'));
        }

        document.addEventListener('DOMContentLoaded', func);
    },

    onLoad(func: (event?: Event) => void) {
        if (document.readyState === 'complete') {
            return func();
        }

        window.addEventListener('load', func);
    },

    getChildIndex(parent: HTMLElement, child: HTMLElement) {
        return Array.from(parent.children).indexOf(child);
    },

    getChildAt<T extends HTMLElement>(parent: HTMLElement, index: number) {
        return parent.children[index] as T;
    },

    getChildCount(parent: HTMLElement) {
        return Array.from(parent.children).length;
    },
};

const HTML = {
    strip(string: string) {
        const el = document.createElement('div');
        el.innerHTML = string;

        return el.textContent || el.innerText || '';
    },

    createNode(string = '') {
        const el = document.createElement('div');
        el.innerHTML = string;

        return el.firstElementChild as typeof el;
    },
};

const EL = {
    getBoundingClientOffset(el: HTMLElement | Window | Document) {
        if (
            el instanceof Window ||
            el instanceof Document ||
            el === document.body
        ) {
            return { left: 0, top: 0 };
        } else {
            return el.getBoundingClientRect();
        }
    },

    fromString(htmlString: string) {
        const el = document.createElement('div');
        el.innerHTML = htmlString;

        return el.firstElementChild as HTMLElement;
    },

    innerHTML<T extends HTMLElement = HTMLElement>(
        selector: string | T,
        htmlString: string,
    ) {
        this.for(selector, (el) => (el.innerHTML = htmlString));
    },

    replaceHTML<T extends HTMLElement = HTMLElement>(
        selector: string | T,
        htmlString: string,
    ) {
        this.for(selector, (el) => el.replaceWith(this.fromString(htmlString)));
    },

    siblings(el: HTMLElement) {
        return Array.from(el.parentElement?.children || []).filter(
            (child) => child != el,
        );
    },

    for<T extends HTMLElement = HTMLElement>(
        selector: string | T,
        func: (value: T, key: number) => void,
        options: { ready?: boolean; root?: string | HTMLElement | null } = {},
    ): void {
        let roots = [document.body];

        if (options.root) {
            roots =
                typeof options.root == 'string'
                    ? Array.from(document.querySelectorAll(options.root))
                    : [options.root];
        }

        const els =
            typeof selector == 'object'
                ? [selector]
                : roots.flatMap((root) =>
                      Array.from<T>(root.querySelectorAll(selector)),
                  );

        if (options.ready !== true) {
            els.forEach(func);
            return;
        }

        return DOM.onReady(() => {
            els.forEach(func);
        });
    },

    listen<T extends HTMLElement = HTMLElement>(
        selector: string | T,
        event: string,
        func: (evt: Event, el: HTMLElement) => void,
        options: {
            prevent?: boolean;
            ready?: boolean;
        } & AddEventListenerOptions = {},
    ) {
        this.for(
            selector,
            (el) => {
                el.addEventListener(
                    event,
                    (e) => {
                        if (options.prevent) {
                            e.preventDefault();
                        }

                        func(e, el);
                    },
                    options,
                );
            },
            options,
        );
    },

    onIntersect(
        selector: string,
        func: (
            entry: IntersectionObserverEntry,
            observer: IntersectionObserver,
        ) => void,
        options = {},
    ) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    return;
                }

                func(entry, observer);
            }, options);
        });

        this.for(
            selector,
            (el) => {
                observer.observe(el);
            },
            options,
        );

        return observer;
    },
};

export { DOM, HTML, EL, Helpers };
